// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-tsx": () => import("/opt/build/repo/src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-gallery-tsx": () => import("/opt/build/repo/src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fotografie-tsx": () => import("/opt/build/repo/src/pages/fotografie.tsx" /* webpackChunkName: "component---src-pages-fotografie-tsx" */),
  "component---src-pages-impressum-tsx": () => import("/opt/build/repo/src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("/opt/build/repo/src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("/opt/build/repo/src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("/opt/build/repo/src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

